@font-face {
  font-family: "Vazirmatn";
  src: url("..//fonts/Vazirmatn-Black.ttf") format("truetype");
  src: url("..//fonts/Vazirmatn-Bold.ttf") format("truetype");
  src: url("..//fonts/Vazirmatn-ExtraBold.ttf") format("truetype");
  src: url("..//fonts/Vazirmatn-ExtraLight.ttf") format("truetype");
  src: url("..//fonts/Vazirmatn-Light.ttf") format("truetype");
  src: url("..//fonts/Vazirmatn-Medium.ttf") format("truetype");
  src: url("..//fonts/Vazirmatn-Regular.ttf") format("truetype");
  src: url("..//fonts/Vazirmatn-SemiBold.ttf") format("truetype");
  src: url("..//fonts/Vazirmatn-Thin.ttf") format("truetype");
  src: url("..//fonts/Vazirmatn-VariableFont_wght.ttf") format("truetype");
}


@font-face {
  font-family: "NotoSans";
  src: url("..//fonts/NotoSans.ttf");
}
