.logoContainer {
  border-radius: 50%;
}
.logo {
  border-radius: 50%;
  padding: 30px;
  background: linear-gradient(to bottom left, #f8f5f0, #fbf9f6);
}

.items {
  color: red !important;
}

.item_selected {
  color: green !important;
  border-left: 2px green solid !important;
}
