.loadingIndicator {
  font-size: 24px;
  text-align: center;
}
.dots {
  padding: 0 20px;
}
.dots::after {
  content: ".";
  display: inline-block;
  width: 1em;
  text-align: right;
  animation: loading 1s steps(4) infinite;
}


.sendBtn{
  background: linear-gradient(-15deg, #1DAEFF 46.9%, #81d1ff 85.42%);
  box-shadow: 0px 21px 27px -10px #8b99af !important;

}


@keyframes loading {
  0% {
    content: ".";
  }
  25% {
    content: "..";
  }
  50% {
    content: "...";
  }
  75% {
    content: "";
  }
}
