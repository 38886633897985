.textInput {
  position: absolute;
  bottom: 20px;
  width: 50%;
  background-color: white;
}

.messageList {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px; /* Add some padding to the message container */
}

.inputBox {
  margin: 20px auto;
}

/* ----> */
.table {
  min-width: 650;
}
.chatSection {
  width: 100%;
  height: 100vh;
  /* padding-top: 25px; */
}

.headBG {
  background-color: "#e0e0e0";
}
.borderRight500 {
  border-right: "1px solid #e0e0e0";
}

.messageArea {
  height: 85vh;
  margin-top: 0;
  overflow-y: auto;
  background: #F5F5F5;
  }

.chatBubble {
  margin: auto;
  margin-top: 5px;
  padding: 15px 10px !important;
  display: flex;
  flex-direction: column;
}

.chatBubbleUser {
  align-self: flex-end;
  background-color: #cfe0f8;
  color:#000;
  border-radius: 10px 10px 0 10px;
  flex-direction: column;
}
.chatAudioBubble{
  padding-left: 5px;
  padding-right: 5px;
}

.chatBubbleBot {
  align-self: flex-start;
  background-color: #1B3764;
  color: #fff;
  border-radius: 10px 10px 10px 0;
  flex-direction: column-reverse;
}
.chatBubbleBot img {
  background-color: #e0e0e0;
}

.s2tLoader {
  margin: auto;
  width: 60px;
  animation-name: pulse-opacity;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes pulse-opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
